import {
  Address,
  OptionsWriteMethod,
  Output,
} from "@unlockdfinance/verislabs-web3";
import { IErc721Collection } from "../collection/ICollection";
import { IErc20Currency } from "../currency/ICurrency";
import { WalletType } from "contracts/types";
import { Attribute } from "./Attribute";
import NftPrices from "./NftPrices";
import MarketItemData from "./MarketItemData";
import { SupportedChainIds } from "clients/verisModule";

export enum OwnerType {
  UNLOCKD = "u-wallet",
  EXTERNAL = "ext-wallet",
  OTHER = "other",
}

export interface SimpleNft {
  collection: Address;
  tokenId: string;
}

export interface NftWithImage extends SimpleNft {
  image: string;
}

export interface NftWithDepositedFlag extends SimpleNft {
  isDeposited: boolean;
  owner: Address;
  underlyingAsset: Address;
}

export interface NftWithImageAndName extends NftWithImage {
  name: string;
}

export interface INft {
  readonly _collection: IErc721Collection;

  readonly chainId: SupportedChainIds
  readonly image: Promise<string>;
  readonly attributes: Promise<Attribute[]>;
  readonly title: Promise<string>;

  readonly prices: Promise<NftPrices>;
  readonly valuation: Promise<bigint>;
  readonly ltv: Promise<bigint>;
  readonly liquidationThreshold: Promise<bigint>;
  readonly availableToBorrow: Promise<bigint>;
  readonly notValuated: Promise<boolean>;

  readonly tokenId: string;
  readonly owner: Promise<Address>;
  readonly isDeposited: boolean;

  readonly lastSalePrice: Promise<bigint | undefined>;

  readonly nftId: string;
  readonly assetId: Address;
  readonly name: string;
  readonly collection: Address;
  readonly alt: string;
  readonly ownerType: Promise<OwnerType>;
  readonly marketItemData: Promise<MarketItemData | null>;
  readonly currency: IErc20Currency;
  readonly isListed: Promise<boolean>;
  readonly isCancelListAvailable: Promise<boolean>;
  readonly nameToShow: string;
  readonly reservoirBid: Promise<{
    amount: bigint;
    netAmount: bigint;
    id: string;
  } | null>;

  getValuationInUsd: () => Promise<bigint>;
  getAvToBorrowInUsd: () => Promise<bigint>;

  transfer: (
    from: Address,
    to: Address,
    options?: OptionsWriteMethod,
    walletType?: WalletType
  ) => Promise<Output<void>>;
}
