"use client";
import React, { MouseEvent, useMemo, useRef, useState } from "react";
import { useOutsideAlerter } from "../logic/hooks";
import classNames from "classnames";
import { IoMdArrowDropdown } from "react-icons/io";

export type Option = {
  value: string;
  label: string;
  handleClick?: () => void;
};

interface CustomDropdownProps {
  className?: string;
  label: string;
  options: Option[];
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  className,
  label,
  disabled,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<any>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
    if (disabled) return;

    onClick?.(event);

    toggleDropdown();
  };

  useOutsideAlerter(wrapperRef, () => setIsOpen(false));

  const buttonStyles = useMemo<string>(
    () =>
      disabled
        ? "bg-[#ffffff99] text-white border-[#9A9A9A]"
        : "bg-primary border-primary text-white hover:border-white focus-visible:border-white",
    [disabled]
  );

  return (
    <div
      ref={wrapperRef}
      className={classNames("relative inline-block text-left h-10", className)}
    >
      <button
        type="button"
        className={classNames(
          buttonStyles,
          "absolute z-20 flex justify-center items-center w-full px-4 py-2 text-center font-semibold rounded-full shadow-sm active:contrast-125 break-words transition-colors duration-75"
        )}
        onClick={handleButtonClick}
      >
        {label}
        <div className="absolute top-0 right-4 flex items-center justify-center h-full">
          <IoMdArrowDropdown
            className={`transition-transform duration-200 transform ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
      </button>
      {isOpen && (
        <>
          <div className="absolute z-10 bg-black top-5 border border-gray-200 border-t-0 border-b-0 w-full h-5"></div>
          <div className="absolute z-30 right-0 top-10 border border-gray-200 border-t-0 w-40 origin-top-right  bg-black  rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-tl-none rounded-tr-none rounded-b-lg transform -rotate-y-180">
            {options.map(({ label, value, handleClick }, index) => (
              <button
                key={value}
                type="button"
                className={classNames(
                  "block w-full px-1 py-6 text-xs text-white hover:bg-secondary focus:text-white",
                  index === options.length - 1 &&
                    "border-transparent border border-t-0 rounded-tl-none rounded-tr-none rounded-b-lg"
                )}
                onClick={(event: MouseEvent<HTMLElement>) => {
                  onClick?.(event);

                  toggleDropdown();

                  handleClick?.();
                }}
              >
                {label}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
