"use client";
import type { ComponentPropsWithRef, FC, ForwardedRef } from "react";
import { createElement, forwardRef } from "react";
import { PortableText } from "@portabletext/react";
import { stringToPortableText } from "../utils";

export type IPortableText = {
  _key: string;
  _type: "block";
  children: {
    _key: string;
    _type: "span";
    text: string;
  }[];
}[];

interface Props extends ComponentPropsWithRef<"div"> {
  value: string;
  className?: string;
}

export const RichText: FC<Props> = forwardRef(
  (props, ref: ForwardedRef<HTMLDivElement>) => {
    const { value, className, ...rest } = props;
    return (
      <div className={className} ref={ref}>
        <PortableText
          {...rest}
          value={stringToPortableText(value)}
          components={{
            block: ({ value }) => (
              <p>
                {value.children.map((child) => {
                  if (child._type === "span" && child.text === "")
                    return createElement("br", {
                      key: child._key,
                    });
                  return createElement(
                    child._type,
                    { key: child._key },
                    child.text
                  );
                })}
              </p>
            ),
          }}
        />
      </div>
    );
  }
);
