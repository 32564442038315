import classNames from "classnames";
import { PropsWithChildren, type FC } from "react";
import { BsChevronLeft } from "react-icons/bs";

type Props = PropsWithChildren<{
  className?: string;
}>;

type BackButtonProps = {
  onClick: () => void;
};

interface SubHeader {
  SubHeaderH1: FC<Props>;
  SubHeaderH2: FC<Props>;
  BackButton: FC<BackButtonProps>;
}

export const CommonSubHeader: FC<Props> & SubHeader = ({
  children,
  className,
}) => {
  return (
    <>
      <section
        className={classNames(`max-w-7xl mx-auto pt-14 mb-8`, [className])}
      >
        {children}
      </section>
    </>
  );
};

const SubHeaderH1: FC<Props> = ({ children, className }) => {
  return (
    <h1
      className={classNames(
        "text-center w-full text-3xl font-bold md:text-3xl xs:text-2xl mt-b xs:mb-3",
        className
      )}
    >
      {children}
    </h1>
  );
};

const SubHeaderH2: FC<Props> = ({ children, className }) => {
  return (
    <h2 className={classNames("text-center w-full text-base", className)}>
      {children}
    </h2>
  );
};

const BackButton: FC<BackButtonProps> = ({ onClick }) => {
  return (
    <button
      className="font-bold text-xl flex items-center gap-1"
      onClick={onClick}
    >
      <BsChevronLeft />
      Back
    </button>
  );
};

CommonSubHeader.SubHeaderH1 = SubHeaderH1;
CommonSubHeader.SubHeaderH2 = SubHeaderH2;
CommonSubHeader.BackButton = BackButton;
