import classNames from "classnames";
import type { FC } from "react";

type Props = {
  className?: string;
  healthFactor?: bigint;
};

export const HealthFactorCircle: FC<Props> = (props) => {
  const { healthFactor, className } = props;

  const bg = !healthFactor
    ? ""
    : healthFactor >= 2e4
    ? "bg-mygreen"
    : healthFactor >= 1.5e4
    ? "bg-yellow-300"
    : healthFactor >= 1e4
    ? "bg-[#FF9B3F]"
    : "bg-myred";

  const noHfStyles = "border border-white border-dotted rounded-full";

  return (
    <div
      className={classNames(
        "w-3 h-3 rounded-full",
        bg,
        !healthFactor ? noHfStyles : "",
        className
      )}
    ></div>
  );
};
