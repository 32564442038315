import classNames from "classnames";
import { FC, useMemo } from "react";
import { MulticollateralImage } from "..";
import { getAltForNft } from "../../logic/helpers";
import NftImage from "./NftImage";
import { INft } from "logic/types/nft/INft";

type Props = {
  className?: string;
  nfts: INft[];
  whiteSquaresDisabled?: boolean;
  rounded?: "sm" | "md" | "lg";
};

export const LoanImage: FC<Props> = (props) => {
  const { className, nfts, whiteSquaresDisabled, rounded = "sm" } = props;

  const roundedStyles = useMemo<string>(
    () =>
      rounded === "md"
        ? "rounded-md"
        : rounded === "lg"
        ? "rounded-lg"
        : "rounded-sm",
    [rounded]
  );

  return (
    <>
      {nfts.length === 0 ? (
        <div
          className={classNames("border border-white border-dotted", className)}
        ></div>
      ) : nfts.length === 1 ? (
        <NftImage
          className={classNames(className, roundedStyles)}
          nft={nfts[0]}
        />
      ) : (
        <MulticollateralImage
          className={className}
          rounded={rounded}
          nfts={nfts.slice(0, 4)}
          whiteSquaresDisabled={whiteSquaresDisabled}
        />
      )}
    </>
  );
};
