"use client";
import { useState } from "react";
import { useToggle, useUpdateEffect } from "react-use";
import useAlertNotification from "../../hooks/useAlertNotification";
import { useModalSwitchNetwork } from "../../hooks/useModalSwitchNetwork";
import { useLockeysHolderType } from "../../hooks/useLockeysHolderType";
import { useModalConnectWallets } from "../../hooks/useModalConnectWallets";
import { useModalSign } from "../../hooks/useModalSign";
import { useWallet } from "../../providers/WalletProvider";
import { useChain } from "components/providers/ChainProvider";
import logic from "../../../logic";
import { LockeysHolderType } from "../../../logic/types";

import UnlockdService from "../../../data/UnlockdService";

// const lockeyHolderFeedbacks = {
//   [LockeysHolderType.TEAM]: `For team lockeys holders dapp will be available from ${app.DATE_TO_FAUCET_TEAM}`,
//   [LockeysHolderType.ONE_ON_ONE]: `For 1/1 lockeys holders dapp will be available from ${app.DATE_TO_FAUCET_ONE_ON_ONE}`,
//   [LockeysHolderType.KEY_SUPPORTER]: `For key supporters lockeys holders dapp will be available from ${app.DATE_TO_FAUCET_KEY_SUPPORTER}`,
//   [LockeysHolderType.HOLDER]: `For lockeys holders dapp will be available from ${app.DATE_TO_FAUCET_HOLDER}`,
//   [LockeysHolderType.NON_HOLDER]: `For non lockeys holders dapp will be available from ${app.DATE_TO_FAUCET_NON_HOLDER}`,
// };

type Hook = (
  isOpen?: boolean,
  toggleModal?: (nextValue?: boolean) => void,
  options?: {
    validators?: {
      validator: () => boolean;
      notification: {
        type: "error" | "success" | "warning" | "loading";
        content: string;
        timeout: number;
      };
    }[];
  }
) => () => void;

export const useWeb3ModalGuard: Hook = (isOpen, toggleModal, options = {}) => {
  const { validators } = options;
  const lockeysHolderType = useLockeysHolderType();
  const [process, setProcess] = useState<
    | "start"
    | "connectWallet"
    | "switchNetwork"
    | "authentication"
    | "open"
    | null
  >(null);
  const [on, launcher] = useToggle(false);

  const [isModalConnectWalletsOpen, toggleModalConnectWallets] =
    useModalConnectWallets();
  const [isModalSignOpen, toggleModalSign] = useModalSign();
  const [isModalSwitchNetworkOpen, toggleModalSwitchNetwork] =
    useModalSwitchNetwork();

  const [, openAlertNotification] = useAlertNotification();

  const { isConnected, address } = useWallet();
  const { chainId, chainIdConnected } = useChain()

  const launcherModal = () => {
    setProcess("start");
    launcher();
  };

  useUpdateEffect(() => {
    if (
      !(
        process === "start" ||
        process === "connectWallet" ||
        process === "switchNetwork" ||
        process === "authentication"
      )
    )
      return;
    if (!isConnected) {
      if (process === "connectWallet") {
        return setProcess(null);
      }
      return toggleModalConnectWallets(true);
    }

    // CODE COMMENTED TO TEST FLOW WITHOUT DATE-HOLDERTYPE VALIDATION
    // HAS TO BE REMOVED ON NEXT COMMITS
    // if (
    //   lockeysHolderType == undefined ||
    //   !logic.isWeb3InteractionsAvailableForUser(lockeysHolderType)
    // ) {
    //   setProcess(null);

    //   openAlertNotification(
    //     "warning",
    //     lockeyHolderFeedbacks[lockeysHolderType!],
    //     2000
    //   );

    //   return;
    // }

    if (chainId !== chainIdConnected) {
      if (process === "switchNetwork") {
        return setProcess(null);
      }
      return toggleModalSwitchNetwork(true);
    }

    if (!UnlockdService.get(chainId).token) {
      if (process === "authentication") {
        return setProcess(null);
      }
      return toggleModalSign(true);
    }

    if (validators) {
      let someValidateFail = false;
      let i = 0;
      do {
        if (!validators[i].validator()) someValidateFail = true;
        else i++;
      } while (!someValidateFail && i < validators.length);

      if (someValidateFail) {
        const { notification } = validators[i];
        setProcess(null);
        openAlertNotification(
          notification.type,
          notification.content,
          notification.timeout
        );
        return;
      }
    }
    setProcess("open");
    toggleModal?.(true);
  }, [on]);

  useUpdateEffect(() => {
    if (!process) return;
    if (!isModalConnectWalletsOpen) {
      const timer = setTimeout(() => {
        setProcess("connectWallet");
        launcher();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isModalConnectWalletsOpen]);

  useUpdateEffect(() => {
    if (!process) return;
    if (!isModalSignOpen) {
      const timer = setTimeout(() => {
        setProcess("authentication");
        launcher();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isModalSignOpen]);

  useUpdateEffect(() => {
    if (!process) return;
    if (!isModalSwitchNetworkOpen) {
      const timer = setTimeout(() => {
        setProcess("switchNetwork");
        launcher();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isModalSwitchNetworkOpen]);

  useUpdateEffect(() => {
    if (!(process === "open")) return;
    // if (chainId !== app.CHAIN.id) {
    //   toggleModal?.(false);
    //   setProcess(null);
    //   const timer = setTimeout(() => {
    //     openAlertNotification(
    //       "warning",
    //       "Please do not change the network in the middle of the process",
    //       10_000
    //     );
    //   }, 300);
    //   return () => clearTimeout(timer);
    // }
    toggleModal?.(false);
    setProcess(null);
    const timer = setTimeout(() => {
      openAlertNotification("warning", "Wallet disconnected", 10_000);
    }, 300);
    return () => clearTimeout(timer);
  }, [address, chainId]);

  useUpdateEffect(() => {
    if (!(process === "open")) return;
    if (!isOpen) setProcess(null);
  }, [isOpen]);

  return launcherModal;
};
