import classNames from "classnames";
import { type FC, type PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const Tag: FC<Props> = (props) => {
  const { className, children } = props;

  return (
    <div
      className={classNames(
        "font-bold text-xs min-w-[70px] px-3 py-0.5 border-2 rounded-full bg-[#312958] flex justify-center items-center",
        className
      )}
    >
      {children}
    </div>
  );
};
