"use client";
import Image from "next/image";
import { useState, type FC } from "react";
import { useDeployUnlockdWallet, useErrorAlert } from "../logic/hooks";
import { generalLiterals } from "../literals";
import { handleErrors } from "../errors";
import CtaPrimary from "./CtaPrimary";
import { BasicProps } from "./types/BasicProps";
import classNames from "classnames";
import track from "logic/track";

const CreateUnlockdWallet: FC<BasicProps> = (props) => {
  const { className } = props;
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { handleCreateWallet } = useDeployUnlockdWallet();

  const handleClick = async () => {
    try {
      track.click('account_click_create')

      setLoading(true);
      await handleCreateWallet();
    } catch (err) {
      setError(handleErrors(err, "createWallet"));
      setLoading(false);
    }
  };

  return (
    <div
      className={classNames(
        "w-[425px] rounded-2xl p-0.5 border-unlockd",
        className
      )}
    >
      <div className="bg-secondary p-[60px] rounded-2xl flex flex-col justify-center items-center gap-[30px]">
        <Image
          src="/assets/images/wallet-front-clay.svg"
          alt="create unlockd wallet"
          width="100"
          height="100"
        />
        <div className="w-[290px] flex flex-col justify-center items-center gap-[15px]">
          <h3 className="w-[210px] text-2xl font-bold text-white text-center">
            {generalLiterals.createWallet.title}
          </h3>
          <p className="text-white text-xs text-center">
            {generalLiterals.createWallet.description}
          </p>
        </div>
        <CtaPrimary className="w-52" disabled={isLoading} onClick={handleClick}>
          {generalLiterals.createWallet.submitCta}
        </CtaPrimary>
      </div>
    </div>
  );
};

export default CreateUnlockdWallet;
