"use client";
import { FC, useState } from "react";
import Image from "next/image";
import classNames from "classnames";
import { useCustomWallet } from "components/providers";
import { INft, OwnerType } from "logic/types/nft/INft";
import { BasicProps } from "components/types/BasicProps";
import { usePromiseProperty } from "logic/hooks";
import { Skeleton } from "@mui/material";

type Props = BasicProps & {
  depositedTag?: boolean;
  walletImage?: boolean;
  nft?: INft;
  src?: string;
  alt?: string;
};

const NftImage: FC<Props> = ({
  className,
  depositedTag = false,
  walletImage = false,
  alt,
  nft,
  src,
}) => {
  const { connector } = useCustomWallet();
  const [loaded, setLoaded] = useState(false);
  const image = usePromiseProperty("image", nft, src);
  const ownerType = usePromiseProperty("ownerType", nft);

  // const isClouldflareSrc = restProps.src.startsWith(
  //   "https://cloudflare-ipfs.com"
  // );

  // const imageLoader = !isClouldflareSrc
  //   ? undefined
  //   : isClouldflareSrc
  //   ? ({}: { src: string; width: number; quality?: number }) => {
  //       return restProps.src;
  //     }
  //   : undefined;

  return (
    <div className={classNames(className, "relative overflow-hidden grid")}>
      {depositedTag && (
        <div className="col-start-1 row-start-1 z-20 flex justify-end">
          <div className="h-fit w-fit mt-2 mr-2 px-3 bg-gradient-to-r from-gray-500 to-gray-700 rounded-xl border-2 border-green-400 font-semibold">
            Deposited
          </div>
        </div>
      )}
      {walletImage && ownerType === OwnerType.UNLOCKD && (
        <Image
          className="absolute bottom-0.5 right-0.5 z-20"
          src="/assets/images/unlockd-wallet-icon.png"
          width={35}
          height={35}
          alt="unlockd wallet"
        />
      )}
      {walletImage && ownerType === OwnerType.EXTERNAL && connector?.icon && (
        <div className="absolute bottom-0.5 right-0.5 z-20 h-[35px] w-[35px] bg-white rounded-lg p-1 flex items-center justify-center">
          <Image
            src={connector.icon}
            alt={`${connector.name} wallet`}
            width={32}
            height={32}
          />
        </div>
      )}
      {image && (
        <Image
          className={classNames(
            "object-cover h-full",
            depositedTag && "col-start-1 row-start-1 z-10 grayscale"
          )}
          fill
          sizes="30vw"
          src={image}
          alt={alt || nft?.alt || "NFT Image"}
          onLoad={() => setLoaded(true)}
        />
      )}
      {!loaded && (
        <Skeleton
          variant="rectangular"
          style={{ backgroundColor: "rgba(255,255,255,0.11)", height: "auto" }}
          className={classNames(
            depositedTag && "col-start-1 row-start-1 z-10 grayscale"
          )}
        />
      )}
    </div>
  );
};

export default NftImage;
