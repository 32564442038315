"use client";
import { Transition } from "@headlessui/react";
import { type FC } from "react";

interface Modal {
  isOpen: boolean;
  toggleModal: () => void;
}

type Hoc = <T extends Modal>(Component: FC<T>) => FC<T>;

export const withModal: Hoc = (Component) => {
  return ({ isOpen, toggleModal, ...restProps }) => (
    <>
      <Transition
        show={isOpen}
        enterFrom="opacity-0"
        enter="transition-opacity duration-300"
        leave="transition-opacity duration-300"
        leaveTo="opacity-0"
        className="fixed top-0 left-1/2 -translate-x-1/2 flex justify-center w-screen h-screen z-50"
        beforeEnter={() =>
          (document.getElementsByTagName("body")[0].style.overflow = "hidden")
        }
        afterLeave={() =>
          (document.getElementsByTagName("body")[0].style.overflow = "")
        }
      >
        <div
          onClick={() => toggleModal(false)}
          className="absolute w-full h-full -z-10 bg-drawer cursor-default"
        ></div>
        <Component isOpen={isOpen} toggleModal={toggleModal} {...restProps} />
      </Transition>
    </>
  );
};
