import { FC } from "react";
import classNames from "classnames";
import { BasicProps } from "./types/BasicProps";

export const Popover: FC<BasicProps> = (props) => {
  const { children, className } = props;

  return (
    <div
      className={classNames(
        "border border-hovercolor rounded-[15px] bg-[#0C1220] flex items-center justify-center font-medium",
        className
      )}
    >
      {children}
    </div>
  );
};
