import Image from "next/image";
import { type FC } from "react";
import classNames from "classnames";
import { useWeb3ModalGuard } from "./modals/shared";
import { CtaPrimary } from ".";

export const ConnectWalletScreen: FC<{ className?: string }> = (props) => {
  const { className } = props;
  const launcher = useWeb3ModalGuard();

  return (
    <section
      className={classNames(
        "w-fit rounded-2xl p-0.5 border-unlockd",
        className
      )}
    >
      <div className="relative bg-secondary rounded-2xl px-[30px] pb-[51px] pt-[57px] flex flex-col justify-center items-center gap-[30px]">
        <div className="w-[150px] h-[150px]">
          <Image
            src="/assets/images/logo-unlock-rotated.png"
            width={150}
            height={150}
            alt="Unlockd logo"
            priority={true}
          />
        </div>
        <div className="w-[508px] flex flex-col items-center justify-center gap-2.5">
          <h3 className="text-2xl font-bold">Please connect your wallet</h3>
          <p className="text-xs">
            Please connect your wallet to see your supplies, borrowings, and
            open positions.
          </p>
        </div>
        <CtaPrimary className="w-40" onClick={launcher}>
          Connect wallet
        </CtaPrimary>
      </div>
    </section>
  );
};
